import type { FC } from 'react';
import React from 'react';

import { type Placement } from '@atlaskit/popper';

import { type DataClassificationLevelProps } from '@atlassian/data-classification-level';

import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { LoadableLazy } from '@confluence/loadable';

import type { ContentClassificationMetadata } from '../hooks/useContentClassification';
import {
	GenericClassificationTagButton,
	TagButtonMenuBehavior,
} from '../shared/GenericClassificationTagButton';
import { TagLoadingAndFlagWrapper } from '../shared/TagLoadingAndFlagWrapper';
import { useEditSubmitCallback } from '../hooks/useEditSubmitCallback';
import { TagButtonUIVariant } from '../constants/TagButtonUIVariant';
import { FlagStatus } from '../constants/FlagStatus';
import { ErrorState } from '../constants/ErrorState';
import { useIsEllipsisClassifyEnabled } from '../hooks/useIsEllipsisClassifyEnabled';

const ClassificationTagButtonError = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ClassificationTagButtonError" */ '../shared/ClassificationTagButtonError'
			)
		).ClassificationTagButtonError,
});

const GenericAddClassificationButton = LoadableLazy({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-ClassificationTagButtonError" */ '../shared/GenericAddClassificationButton'
			)
		).GenericAddClassificationButton,
});

type ContentTypeClassificationProps = {
	spaceKey: string;
	contentId: string;
	contentType: string;
	contentClassificationMetadata: ContentClassificationMetadata;
	userOffline?: boolean;
	readOnly?: boolean;
	variant?: TagButtonUIVariant;
	onEditMenuToggle?: () => void;
	onReadMenuToggle?: () => void;
	popupPlacementOverride?: Placement;
	spacing?: DataClassificationLevelProps['spacing'];
};

const ContentTypeClassificationComponent = ({
	spaceKey,
	contentId,
	contentType,
	contentClassificationMetadata,
	readOnly = true,
	variant = TagButtonUIVariant.default,
	popupPlacementOverride,
	spacing,
}: ContentTypeClassificationProps) => {
	const { isEllipsisClassifyEnabled } = useIsEllipsisClassifyEnabled();

	const handleSubmit = useEditSubmitCallback({
		contentId,
		contentClassificationMetadata,
	});

	const {
		classification: currentClassification,
		hasNoClassification,
		shouldDisplayClassification,
		spaceClassification,
		flagStatus,
		errorState,
		contentLevelError,
		spaceLevelError,
		spaceDefaultIsOverridden,
		getClassificationLevelFromId,
	} = contentClassificationMetadata;

	const isLoading = flagStatus === FlagStatus.LOADING;

	const isAddNewClassificationMode =
		!readOnly && hasNoClassification && !shouldDisplayClassification;

	if (isAddNewClassificationMode) {
		if (isEllipsisClassifyEnabled) return null;
		return (
			<TagLoadingAndFlagWrapper isLoading={isLoading} flagStatus={flagStatus}>
				<GenericAddClassificationButton
					spaceKey={spaceKey}
					getClassificationLevelFromId={getClassificationLevelFromId}
					onEditSubmit={handleSubmit}
					loading={isLoading}
					variant={variant}
				/>
			</TagLoadingAndFlagWrapper>
		);
	}

	if (!currentClassification || errorState === ErrorState.NULL) return null;

	if (errorState === ErrorState.MESSAGE) {
		return (
			<ClassificationTagButtonError
				contentLevelError={contentLevelError}
				spaceLevelError={spaceLevelError}
			/>
		);
	}

	return (
		<TagLoadingAndFlagWrapper isLoading={isLoading} flagStatus={flagStatus}>
			<GenericClassificationTagButton
				spaceKey={spaceKey}
				contentType={contentType}
				currentClassification={currentClassification}
				spaceDefaultClassification={spaceClassification}
				spaceDefaultIsOverridden={spaceDefaultIsOverridden}
				getClassificationLevelFromId={getClassificationLevelFromId}
				onEditSubmit={handleSubmit}
				menuBehavior={readOnly ? TagButtonMenuBehavior.readOnly : TagButtonMenuBehavior.readAndEdit}
				loading={isLoading}
				variant={variant}
				popupPlacementOverride={popupPlacementOverride}
				spacing={spacing}
			/>
		</TagLoadingAndFlagWrapper>
	);
};

const ContentTypeClassificationGetter: FC<ContentTypeClassificationProps> = (props) => {
	return <ContentTypeClassificationComponent {...props} />;
};

const ContentTypeClassificationWithErrorBoundary = withTransparentErrorBoundary({
	attribution: Attribution.DLP,
})(ContentTypeClassificationGetter);

export const ContentTypeClassification: FC<ContentTypeClassificationProps> = (props) => (
	<ContentTypeClassificationWithErrorBoundary {...props} />
);
